import React, { useEffect, useState } from "react";
import "./navigation-bar.css";
import { Button, Menu } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { ReactComponent as LogoSC } from "../image/logo/logo.svg";
import LogoCRA from "../../assets/img/logo/LogoCRA.png";
import {findDefM} from "../../utils/datas";
const NavBar = ({
  setClickMenu,
  dataNav,
  iconData,
  collapse,
  theme,
  mode,
  setCollapse,
  subItem,
  setSubItem,
  setLinkType,
  //
  clickMenu,
}) => {
  const { SubMenu } = Menu;

  const [isClickMenu, setIsClickMenu] = useState(); // хранит типы ссылки для модуля
  let handleClick = (e) => {
    setSubItem(e.keyPath);
  };
  let iconRec = (item, ic) => {
    let type = 'dark';
    return [item.filter((i) => i.name.includes(ic))[0], type];
  }; //  - обработка иконки по названию и получения svg
  let moduleSelected = (item) => {
    setClickMenu([item.title, item.module]);
    setLinkType(item.linkType);
  }; // - сохраняет выбранный пункт меню для демонстрации
  let AntMenuCreated = (item) => {
    let subClass = `navigation_bar noCopy`;
    let popupClass = `NavBar_item ${collapse ? "collapse" : "noCollapse"} ${
      theme ? theme + "Theme" : ""
    }`;
    let iconF = (item) => {
      return (
        <div
          className={`icon_navMenu ${
            iconRec(iconData, item.iconName)[1]
          }-theme`}
        >
          {iconRec(iconData, item.iconName)[0].SVG}
        </div>
      );
    };
    const MenuItems = (items) => {
      let item = items;

      switch (item.linkType[0]) {
        case "outLink":
          return (
            <Menu.Item
              key={item.title}
              icon={
                <div
                  className={`icon_navMenu ${
                    iconRec(iconData, item.iconName)[1]
                  }-theme noCopy 111`}
                >
                  {iconRec(iconData, item.iconName)[0].SVG}
                </div>
              }
            >
              <a
                href={item.module}
                target={"_blank"}
                title={"переход на внешнюю страницу"}
                key={item.title}
                rel="noreferrer"
              >
                {item.title}
              </a>
            </Menu.Item>
          );
        default:
          return (
            <Menu.Item
              key={item.title}
              icon={
                <div
                  className={`icon_navMenu ${
                    iconRec(iconData, item.iconName)[1]
                  }-theme noCopy 111`}
                >
                  {iconRec(iconData, item.iconName)[0].SVG}
                </div>
              }
              onClick={() => {
                checkUrl(item)[0] &&
                  item.linkType[0] !== "outLink" &&
                  moduleSelected(item);
              }}
            >
              {checkUrl(item)[1]}
            </Menu.Item>
          );
      }
    };

    let isChild = (item) => {
      return item.children && item.children.length > 0;
    }; // сокращение проверки на вложенность
    return item.map((i) =>
      i.isDirectory ? (
        <SubMenu
          key={i.title}
          title={i.title}
          icon={iconF(i)}
          className={subClass}
          popupClassName={popupClass}
        >
          {isChild(i) &&
            i.children.map((it) =>
              it.isDirectory ? (
                <SubMenu
                  key={it.title}
                  title={it.title}
                  icon={iconF(it)}
                  className={subClass}
                  popupClassName={popupClass}
                >
                  {isChild(it) &&
                    it.children.map((its) =>
                      its.isDirectory ? (
                        <SubMenu
                          title={its.title}
                          key={its.title}
                          icon={iconF(its)}
                          className={subClass}
                          popupClassName={popupClass}
                        >
                          {isChild(its) &&
                            its.children.map((itsr) =>
                              itsr.isDirectory ? (
                                <SubMenu
                                  key={itsr.title}
                                  title={itsr.title}
                                  icon={iconF(itsr)}
                                  className={subClass}
                                  popupClassName={popupClass}
                                />
                              ) : (
                                MenuItems(itsr)
                              )
                            )}
                        </SubMenu>
                      ) : (
                        MenuItems(its)
                      )
                    )}
                </SubMenu>
              ) : (
                MenuItems(it)
              )
            )}
        </SubMenu>
      ) : (
        MenuItems(i)
      )
    );
  }; // - генератор пунктов навигационного меню

  useEffect(() => {
    clickMenu && setIsClickMenu([clickMenu[0]]);
  }, [clickMenu]);

  return (
    <div
      className={`NavBar_wrapper ${theme} ${
        collapse ? "collapse" : "noCollapse"
      } noCopy`}
    >
      {subItem && (
        <div className={`NavBar_wrap`}>
          <div className={`NavMenu_logo`}>
            <Button
              type="primary"
              onClick={() => setCollapse(!collapse)}
              className={`NavMenu_btn`}
            >
              {React.createElement(
                collapse ? MenuUnfoldOutlined : MenuFoldOutlined
              )}
            </Button>
            <div className={`NavMenu_logo_item ${!collapse ? "active" : "noActive"}`} onClick={() => dataNav && setClickMenu([findDefM(dataNav).title, findDefM(dataNav).module])}>
              <img src={LogoCRA} alt="лого ситуационного центра города алматы"/>
              <span>Ситуационный <br />центр</span>
            </div>
          </div>
          {isClickMenu && (
            <Menu
              onClick={handleClick}
              mode={mode}
              theme={theme}
              inlineCollapsed={collapse}
              className={`NavBar_tree ${theme}`}
            >
              {subItem && AntMenuCreated(dataNav && dataNav)}
            </Menu>
          )}
        </div>
      )}
      {!collapse && (
        <span className={`click_area_nav`} onClick={() => setCollapse(true)} />
      )}
    </div>
  );
};

export default NavBar;

let checkUrl = (item) => {
  if (item.linkType[0] === "outLink") {
    return [
      false,
      <a
        href={item.module}
        target={"_blank"}
        title={"переход на внешнюю страницу"}
        rel="noreferrer"
      >
        {item.title}
      </a>,
    ];
  } else {
    return [true, item.title];
  }
};
