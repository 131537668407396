import '../assets/test/LogoCRA.png'
import img1 from '../assets/test/1.png'
import img2 from '../assets/test/2.png'
import img3 from '../assets/test/3.png'
import img4 from '../assets/test/4.png'
import img5 from '../assets/test/5.png'
import img6 from '../assets/test/6.png'
import img7 from '../assets/test/7.png'
import img8 from '../assets/test/8.png'
import img9 from '../assets/test/9.png'
import img10 from '../assets/test/10.png'
import img11 from '../assets/test/11.png'
import img12 from '../assets/test/12.png'
import img13 from '../assets/test/13.png'
import img14 from '../assets/test/14.png'
import img15 from '../assets/test/15.png'
import img16 from '../assets/test/16.png'

export const CardData=[
    {
        id:1,
        type:'service',
        category:{
            nameRu:'Карта города',
            nameKz:'Қала картасы'
        },
        name:{
            nameRu:'Инфраструктура',
            nameKz:'Инфрақұрылым'
        },
        img: img1,
        link:'https://gis.smartalmaty.kz/infra/'
    },
    {
        id:1,
        type:'service',
        category:{
            nameRu:'Карта города',
            nameKz:'Қала картасы'
        },
        name:{
            nameRu:'Мониторинг районов',
            nameKz:'Аудандар мониторингі'
        },
        img: img2,
        link:'https://opendata.smartalmaty.kz/almaty-monitoring/'
    },
    {
        id:1,
        type:'service',
        category:{
            nameRu:'Карта города',
            nameKz:'Қала картасы'
        },
        name:{
            nameRu:'Аналитика инклюзии',
            nameKz:'Инклюзия талдауы'
        },
        img: img3,
        link:'https://app.powerbi.com/view?r=eyJrIjoiZDE0NDdkZTktZjcyZS00MzM5LWI4YjgtNGY5YWEzY2RiYWNhIiwidCI6IjQyOTMyNzAwLTYzMjgtNDMxNS1iMGE0LTg0NTJiNTUzODcxOSIsImMiOjl9'
    },
    {
        id:2,
        type:'service',
        category:{
            nameRu:'Городские сервисы',
            nameKz:'Қалалық қызметтер'
        },
        name:{
            nameRu:'Предложения инициатив',
            nameKz:'Бастамалар ұсыныстары'
        },
        img: img4,
        link:'https://initiative.smartalmaty.kz/'
    },
    // {
    //     id:2,
    //     type:'service',
    //     category:{
    //         nameRu:'Городские сервисы',
    //         nameKz:'Қалалық қызметтер'
    //     },
    //     name:{
    //         nameRu:'Встречи акима с населением',
    //         nameKz:'Әкімнің халықпен кездесуі'
    //     },
    //     img: img5,
    //     link:'https://met.smartalmaty.kz/'
    // },
    {
        id:2,
        type:'service',
        category:{
            nameRu:'Городские сервисы',
            nameKz:'Қалалық қызметтер'
        },
        name:{
            nameRu:'Маслихат',
            nameKz:'Мәслихат'
        },
        img: img6,
        link:'https://maslikhat.smartalmaty.kz/'
    },
    {
        id:3,
        type:'service',
        category:{
            nameRu:'Социальная сфера',
            nameKz:'Әлеуметтік сала'
        },
        name:{
            nameRu:'Аналитика вакансий',
            nameKz:'Жұмыс аналитикасы'
        },
        img:img7,
        link:'https://app.powerbi.com/view?r=eyJrIjoiZTNkZGQxNTEtYmU4NS00ZmQ5LThmMTctYTdlODI0ODgwNGIwIiwidCI6IjQyOTMyNzAwLTYzMjgtNDMxNS1iMGE0LTg0NTJiNTUzODcxOSIsImMiOjl9'
    },
    // {
    //     id:3,
    //     type:'module',
    //     "link": "/schools/",
    //     "linkType": ["module", "local"],
    //     category:{
    //         nameRu:'Социальная сфера',
    //         nameKz:'Әлеуметтік сала'
    //     },
    //     name:{
    //         nameRu:'Мониторинг школ',
    //         nameKz:'Мектептер мониторингі'
    //     },
    //     img:img8,
    // },
    // {
    //     id:3,
    //     type:'module',
    //     "link": "/ddo/",
    //     "linkType": ["module", "local"],
    //     category:{
    //         nameRu:'Социальная сфера',
    //         nameKz:'Әлеуметтік сала'
    //     },
    //     name:{
    //         nameRu:'Мониторинг ДДО',
    //         nameKz:'МДБ мониторингі'
    //     },
    //     img:img9,
    // },
    /*{
        id:3,
        type:'module',
        "link": "/afisha/",
        "linkType": ["module", "local"],
        category:{
            nameRu:'Социальная сфера',
            nameKz:'Әлеуметтік сала'
        },
        name:{
            nameRu:'Культурные мероприятия',
            nameKz:'Мәдени іс-шаралар'
        },
        img:img10,
    },*/
    {
        id:3,
        type:'module',
        "link": "/culture-object/",
        "linkType": ["module", "local"],
        category:{
            nameRu:'Социальная сфера',
            nameKz:'Социальная сфера'
        },
        name:{
            nameRu:'Объекты культуры',
            nameKz:'Мәдениет объектілері'
        },
        img:img11,
    },
    {
        id:4,
        type:'module',
        "link": "/szpt/",
        "linkType": ["module", "local"],
        category:{
            nameRu:'Экономика',
            nameKz:'Экономика'
        },
        name:{
            nameRu:'СЗПТ',
            nameKz:'СЗПТ'
        },
        img:img12,

    },
    {
        id:4,
        type:'module',
        "link": "/prices/",
        "linkType": ["module", "local"],
        category:{
            nameRu:'Экономика',
            nameKz:'Экономика'
        },
        name:{
            nameRu:'Мониторинг цен',
            nameKz:'Баға мониторингі'
        },
        img:img13,

    },
    /*{
        category:{
            nameRu:'Экономика',
            nameKz:'Экономика'
        },
        name:{
            nameRu:'Стартап экосистема',
            nameKz:'Стартап экожүйесі'
        },
        img:img8,
        link:'https://UserRO:123qweASD@power.smartalmaty.kz/powerbi/?id=73b140d5-1b67-4bb0-a218-6858409df95f'
    },*/
/*    {
        category:{
            nameRu:'Другие модули',
            nameKz:'Басқа модульдер'
        },
        name:{
            nameRu:'Индикаторы районов',
            nameKz:'Аудан индикаторлары'
        },
        img:img8,
        link:'https://UserRO:123qweASD@power.smartalmaty.kz/powerbi/?id=73b140d5-1b67-4bb0-a218-6858409df95f'
    },*/
    /*{
        category:{
            nameRu:'Другие модули',
            nameKz:'Басқа модульдер'
        },
        name:{
            nameRu:'Мониторинг ЧС',
            nameKz:'ТЖ мониторингі'
        },
        img:img8,
        link:'https://UserRO:123qweASD@power.smartalmaty.kz/powerbi/?id=73b140d5-1b67-4bb0-a218-6858409df95f'
    },*/
    {
        id:5,
        type:'module',
        "link": "/transport-out/",
        "linkType": ["module", "local"],
        category:{
            nameRu:'Другие сферы',
            nameKz:'Басқа салалар'
        },
        name:{
            nameRu:'Мониторинг транспорта',
            nameKz:'Көлік мониторингі'
        },
        img:img14,

    },
    {
        id:5,
        type:'module',
        "link": "/ecological-monitoring/",
        "linkType": ["module", "local"],
        category:{
            nameRu:'Другие сферы',
            nameKz:'Басқа салалар'
        },
        name:{
            nameRu:'Экологический мониторинг',
            nameKz:'Экологиялық мониторинг'
        },
        img:img15,

    },
    /*{
        category:{
            nameRu:'Другие модули',
            nameKz:'Басқа модульдер'
        },
        name:{
            nameRu:'Зеленые насаждения',
            nameKz:'Жасыл кеңістіктер'
        },
        img:img8,
        link:'https://UserRO:123qweASD@power.smartalmaty.kz/powerbi/?id=73b140d5-1b67-4bb0-a218-6858409df95f'
    },*/
    {
        id:5,
        type:'module',
        "link": "/participation-budget/",
        "linkType": ["module", "local"],
        category:{
            nameRu:'Другие сферы',
            nameKz:'Басқа салалар'
        },
        name:{
            nameRu:'Бюджет народного участия',
            nameKz:'Халық қатысатын бюджет'
        },
        img:img16,

    },


]
export const menuItem = ['Карта города','Городские сервисы','Аналитика данных']
export const FilterCard = (arr, names=null) =>  {
    if(names) {
        return arr.filter(obj => obj.name);
    }
    return arr
}
export const getUniqueCategories = (data) =>  {
    const uniqueCategories = {};

    data.forEach(item => {
        const { id, category } = item;
        const categoryId = id;

        if (!uniqueCategories[categoryId]) {
            uniqueCategories[categoryId] = {
                id: categoryId,
                nameRu: category.nameRu,
                nameKz: category.nameKz
            };
        }
    });

    return Object.values(uniqueCategories);
}

